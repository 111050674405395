<template>
  <section class="gardenManagementPage">
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <el-button type="success" @click="add" class="turnBule">添加</el-button>
    </SearchForm>
 

    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <!-- <el-table-column label="序号" min-width="30">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column> -->
        <el-table-column prop="title" label="标题" min-width="30" />
        <el-table-column prop="pageUrl" label="页面链接" min-width="30" />
        <el-table-column prop="picUrl" label="轮播图" min-width="60">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.picUrl" width="400" height="150" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" min-width="30" />

        <el-table-column width="500" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="textOrange" @click="editorClick(scope.row)"
              >修改</el-button
            >
            <el-button type="text" class="textOrange" @click="delteClick(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </TableContainer>

    <!-- 添加分类s -->
    <GDialog
      :dialog.sync="themeDialog"
      @btnSure="submitThemeForm()"
      @btnClose="resetThemeForm()"
      width="40%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="标题" label-width="100px">
          <div style="width: 320px">
            <el-input
              v-model.trim="parmas.title"
              placeholder="请输入内容标题"
              type="text"
            />
          </div>
        </el-form-item>
        <el-form-item label="链接" label-width="100px">
          <div style="width: 320px">
            <el-input
              v-model.trim="parmas.pageUrl"
              placeholder="请输入页面链接"
              type="text"
            />
          </div>
        </el-form-item>
        <el-form-item label="排序" label-width="100px">
          <div style="width: 320px">
            <el-input
              v-model.trim="parmas.sort"
              placeholder="请输入排序数字"
              type="number"
            />
          </div>
        </el-form-item>
        <el-form-item label="轮播图400*620" label-width="150px">
          <uploadPics
            :limit="1"
            @fileList="imageFileClick"
            :file-list.sync="imageFileList"
            @moveList="moveFileClick"
          ></uploadPics>
        </el-form-item>
      </el-form>
    </GDialog>
    <!-- 添加分类e -->
  </section>
</template>

<script>
import {
  querycshopmallBanner,
  addcshopmallBanner,
  updatecshopmallBanner,
  delcshopmallBanner,
  // updatejfClass,
  // addjfClass,
  // deljfClass,
} from "@api/bx/api_contentManagement";
import Tinymce from "./tinymce";
import uploadPics from "@/components/Upload/uploadPics.vue";
export default {
  components: { Tinymce, uploadPics },
  data() {
    return {
      themeDialog: {
        title: "添加",
        visible: false,
      },
      tiny: {
        value: undefined,
      },
      searchForm: {
        page: 1,
        size: 15,
        classname: "",
      },
      total: 0,
      tableData: [],
      pageSizes: [15, 30, 45, 60, 75],
      classname: "",
      editor: false,
      row: {},
      imageFileList: [],
      parmas: {
        picUrl: "",
        title: "",
        pageUrl: "",
        sort: "",
      },
    };
  },
  created() {
    this.querycshopmallBanner();
  },
  methods: {
    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },
    clearData() {
      this.parmas.picUrl = "";
      this.parmas.title = "";
      this.parmas.pageUrl = "";
      this.parmas.sort = "";
      this.imageFileList = [];
      this.parmas.picUrl = "";
      
    },
    imageFileClick(msg) {
      // console.log(msg);
      this.parmas.picUrl = msg.rootPath;
    },
    moveFileClick(msg) {
      this.imageFileList = [];
      this.parmas.picUrl = "";
    },
    // 列表
    querycshopmallBanner() {
      querycshopmallBanner({}).then((res) => {
        this.tableData = res;
        // this.total = res.total;
      });
    },

    //添加
    add() {
      this.clearData();
      this.editor = false;
      this.themeDialog.visible = true;
    },
    //确定
    submitThemeForm() {
      if (this.parmas.title == "") {
        this.$alert("请输入标题", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.sort == "") {
        this.$alert("请输入排序序号", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.picUrl == "") {
        this.$alert("请上传轮播图", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.editor) {
        this.parmas.id = this.row.id;
        updatecshopmallBanner(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.querycshopmallBanner();
        });
      } else {
        addcshopmallBanner(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.querycshopmallBanner();
        });
      }
    },

    //取消按钮
    resetThemeForm() {
      this.themeDialog.visible = false;
      // this.clearData();
    },
    //编辑
    editorClick(row) {
      // this.clearData();
      this.editor = true;
      this.row = row;
      this.parmas = row;
      this.imageFileList = [
        {
          name: "",
          url: row.picUrl,
        },
      ];
      this.themeDialog.visible = true;
    },
    // //删除
    delteClick(row) {
      this.$confirm("永久删除" + row.title + "轮播图这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delcshopmallBanner({ id: row.id }).then((res) => {
          this.querycshopmallBanner();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
</style>
<style>
.tox-tinymce-aux {
  z-index: 3333333;
}
</style>
